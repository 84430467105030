export const InitialSort = [
    {
        name: 'state',
        sortField: 'uploadStateName',
        ascending: true,
    },
    {
        name: 'id',
        sortField: 'title',
        ascending: true,
    },
    {
        name: 'creationDate',
        ascending: true,
    },
];