import { Component, Watch } from 'vue-property-decorator';
import Browser from '@/support/browser';
import LocalData from '@/support/localStorage';
import PlayerApi from '@/support/playerApi';
import ReportPageBase from '@/support/reportPageBase';
import Util from '@/support/utility';
import { CompletedColumns } from './completedColumns';
import { IGridColumn, IUploadDto } from '@/interfaces';
import { PendingColumns } from './pendingColumns'
import { InitialSort } from './initialSort';

const SelectStudy = '(All Studies)';

@Component({
    filters: {
        capitalize(str: string) {
            return str.charAt(0).toUpperCase() + str.slice(1);
        },

        formatDate(value: string) {
            return Util.formatDate(value);
        }
    }
})
export default class UploadReportComponent extends ReportPageBase {
    options = {
        limit: Browser.getIntParam('limit', 0),
        filter: Browser.getParam('filter', null),
        refreshTimeout: Browser.getIntParam('refreshTimeout', 5 * 60) * 1000,
        study: LocalData.get('uploads.study', null),
    };

    selected = [];
    videos: IUploadDto[] = [];

    study = {
        show: false,
        initial: <string>null,
        selected: <string>null,
        list: <string[]>[],
    };

    pendingColumns: IGridColumn[] = PendingColumns;
    completedColumns: IGridColumn[] = CompletedColumns;

    timers = {
        refreshIntervalId: <any>0,
    };

    $refs = {
        pendingGrid: null,
        completedGrid: null,
        uploadsPrompt: null,
    };

    get isValidStudy() {
        return this.study.selected && this.study.selected != SelectStudy;
    }

    get uploads() {
        if (this.isValidStudy)
            return this.videos.filter(item => item.study == this.study.selected);

        return this.videos;
    }

    get completedVideos() {
        return this.uploads.filter(item => item.uploadStateName == 'Success' || item.uploadStateName == 'Failed');
    }

    get pendingVideos() {
        return this.uploads.filter(item => item.uploadStateName != 'Success' && item.uploadStateName != 'Failed');
    }

    get currentStudy() {
        if (this.study.initial)
            return this.study.initial;

        return this.isValidStudy ? this.study.selected : null;
    }

    get processing() {
        return this.pendingVideos.filter(item => item.uploadStateName != 'Waiting').length;
    }

    get updateVideoLinksUrl() {
        if (!this.isValidStudio)
            return null;

        let studio = this.studioSelection.selected.studio;
        let study = this.currentStudy;

        return studio && study ? 'https://urvideostream/UpdatePlaytestVideoLinks/' + studio + '/' + encodeURIComponent(study) : null;
    }

    @Watch('study.selected')
    async onStudyChanged(val: string, oldVal: string) {
        if (!this.loaded) return;

        let selected = this.isValidStudy ? this.study.selected : null;
        LocalData.save('uploads.study', selected);

        if (this.toggleStudySelection()) {
            this.refreshGrid();
        }
    }

    @Watch('studioSelection.selected')
    async onStudioChanged2(val: string, oldVal: string) {
        if (!this.loaded) return;

        let result1 = this.toggleStudioSelection(this.pendingColumns);
        let result2 = this.toggleStudioSelection(this.completedColumns);

        if (result1 || result2) {
            this.refreshGrid();
        }
    }

    refreshGrid() {
        super.refreshGrid();

        let grid = this.$refs.pendingGrid;
        if (grid)
            grid.refresh();

        grid = this.$refs.completedGrid;
        if (grid)
            grid.refresh();
    }

    created() {
        this.reportPageCreated('uploadsReport', InitialSort, true);

        Debug.setDebugModule('App', this);
        this.study.show = this.study.initial == null;
    }

    mounted() {
        super.mounted();

        if (this.options.filter)
            this.filterKey = this.options.filter;

        if (this.options.limit)
            this.state.limit = this.options.limit;

        this.toggleStudioSelection(this.pendingColumns);
        this.toggleStudioSelection(this.completedColumns);
        this.mountStudySelection();
    }

    beforeDestroy() {
        super.beforeDestroy();
    }

    async getData() {
        return await this.getUploads();
    }

    loadStudies(studies: string[]) {
        let selected = this.study.selected || this.options.study;
        this.options.study = null;

        this.study.list.splice(0, this.study.list.length);
        this.study.list.push(SelectStudy);
        studies.forEach(study => {
            this.study.list.push(study);
        });

        if (selected && this.study.list.length) {
            let found = this.study.list.find(item => item == selected);
            this.study.selected = found;
        }

        if (!this.study.selected && this.study.list.length) {
            this.study.selected = this.study.list[0];
        }
    }

    async getUploads() {
        try {
            let studio = this.studioSelection.selected.studio;

            this.href = null;
            let response = await PlayerApi.uploadList(studio, this.study.initial, this.state.limit);

            this.loadStudies(response.studies);

            this.videos.splice(0, this.videos.length);

            response.uploads.forEach(video => {
                if (video.isLive) return;

                let videoObj = <IUploadDto>video;
                this.setPrivateMembers(videoObj);

                this.videos.push(videoObj);
            });

            Debug.log('getUploads', this.videos.length, 'Videos');

            return true;
        } catch (err) {
            let message = err.message || 'ERROR';

            Util.showError('Failed to get videos list ' + message);
            return null;
        }
    }

    setPrivateMembers(video: IUploadDto) {

        let params = video.id;
        let url = window.location.origin + '/Studio/' + video.studio + '?stream=' + encodeURIComponent(params);

        if (!video.mp4)
            video.shareUrl = url;

        let studio = this.studios.find(item => item.studio == video.studio);
        if (studio) {
            video.studioTitle = studio.title;
        } else {
            video.studioTitle = video.studio;
        }
    }

    videoUrl(video: IUploadDto) {
        let params = video.study + '/' + video.title;
        let url = window.location.origin + '/Studio/' + video.studio + '?stream=' + encodeURIComponent(params);

        return url;
    }

    finalizeLoad() {
        if (!this.timers.refreshIntervalId) {
            this.timers.refreshIntervalId = setInterval(() => {
                // Debug.log("refresh from refreshInterval", this.options.refreshTimeout);
                this.refresh();
            }, this.options.refreshTimeout);
        }
        // Debug.log("refresh initialized", this.options.refreshTimeout);
    }

    async resetAssets() {
        let studio = this.studioSelection.selected.studio;
        await PlayerApi.resetAssets(studio);

        this.getUploads();
    }

    mountStudySelection() {
        let idx = this.pendingColumns.findIndex(item => item.name == 'study');
        if (idx != -1) {
            if (this.study.initial) {
                this.pendingColumns[idx].hide = true;
            }
        }

        idx = this.completedColumns.findIndex(item => item.name == 'study');
        if (idx != -1) {
            if (this.study.initial) {
                this.completedColumns[idx].hide = true;
            }
        }
    }

    toggleStudySelection() {
        if (this.study.initial)
            return false;

        let show = this.study.selected == SelectStudy;

        let idx = this.pendingColumns.findIndex(item => item.name == 'study');
        if (idx != -1) {
            this.pendingColumns[idx].hide = !show;
        }

        idx = this.completedColumns.findIndex(item => item.name == 'study');
        if (idx != -1) {
            this.completedColumns[idx].hide = !show;
        }

        return true;
    }

    checkUpdateVideoLinks(e) {
        if (!this.updateVideoLinksUrl) {

            let prompt = this.$refs.uploadsPrompt;
            if (prompt) {
                prompt.hideSubmit = true;
                prompt.show();
            }
            if (e)
                e.preventDefault();
            return false;
        }

        return true;
    }

    // base event handlers need to exist in outer class
    onGridDataChanged(count: number) {
        super.onGridDataChanged(count);
    }
}
