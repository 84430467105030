import { IGridColumn } from '@/interfaces';

export const CompletedColumns: IGridColumn[] = <any>[
    {
        name: 'state',
        title: 'State',
        sortField: 'uploadStateName',
    },
    {
        name: 'id',
        title: 'Video',
        sortField: 'title',
    },
    {
        name: 'studioTitle',
        title: 'Studio',
        hide: false,
    },
    {
        name: 'study',
        title: 'Study',
        hide: false,
    },
    {
        name: 'sid',
        title: 'SID',
    },
    {
        name: 'streamName',
        title: 'File',
    },
    {
        name: 'creationDate',
        title: 'Created',
        callback: 'formatDate',
    },
    {
        name: 'shareUrl',
        title: 'Url',
    },
];
